@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Opulent';
  src: url('./OPN.woff') format('woff');
 
}
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

body{
  background-color: black;
}



