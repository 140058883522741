.container {
    padding: 4rem 2rem;
    background-color: #1a1a1a; /* Assuming a dark background */
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .heading {
    font-family: 'Opulent', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1.25;
    color: #FFFFFF;
  }
  
  .para {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.75;
    color: #FFFFFF;
    white-space: pre-line;
  }
  
  .cardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .iconWrapper {
    width: 2rem;
    height: 2rem;
  }
  
  .icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .cardheading {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4;
    letter-spacing: 0.7px;
    color: #FFFFFF;
  }
  
  .cardpara {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .imageWrapper {
    width: 100%;
  }
  
  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  @media (min-width: 768px) {
    .content {
      flex-direction: row;
      align-items: center;
    }
  
    .textContent {
      flex: 1;
    }
  
    .imageWrapper {
      flex: 1;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 3rem 1.5rem;
    }
  
    .heading {
      font-size: 2rem;
    }
  
    .para {
      font-size: 1rem;
    }
  
    .cardGrid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 2rem 1rem;
    }
  
    .heading {
      font-size: 1.75rem;
    }
  
    .cardheading {
      font-size: 1.125rem;
    }
  
    .cardpara {
      font-size: 0.875rem;
    }
  }