.sliderContainer {
    background-color: #000000;
    color: #FFFFFF;
    padding: 60px 0;
    text-align: center;
}

.subHeading {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
}

.heading {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
}

.mySwiper {
    padding: 20px 0;
}

.testimonialCard {
    background-color: #1A1A1A;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    display: flex;
}

.imageContainer {
    width: 40%;
    overflow: hidden;
}

.personImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonialContent {
    width: 60%;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.name {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 5px;
}

.company {
    font-size: 12px;
    color: #9C9C9C;
    margin-bottom: 15px;
}

.testimonialText {
    font-size: 16px;
    line-height: 1.5;
}

.navigationContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
}

.navButton {
    background: none;
    border: none;
    font-size: 24px;
    color: #808080;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.navButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

:global(.swiper-slide) {
    height: auto;
}

@media (max-width: 1200px) {
    .mySwiper {
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    .testimonialCard {
        flex-direction: column;
    }

    .imageContainer,
    .testimonialContent {
        width: 100%;
    }
}