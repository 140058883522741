.modalOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .container {
    background: #FFFFFF;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    max-width: 500px;
    z-index: 1000;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .heading {
    font-family: 'Opulent', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 62px;
    color: #000000;
  }
  
  .headingdiv {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #CC1C00;
    width: fit-content;
  }
  
  .formContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .inputdiv {
    border-bottom: 1px solid black;
    width: 100%;
  }
  
  .input {
    color: black;
    outline: none;
    border: none;
    padding: 0.5rem;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }
  
  textarea.input {
    resize: none;
    height: 150px;
  }
  
  .checkboxContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  
  .checkbox {
    width: 20px;
    height: 20px;
  }
  
  .checktext {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }
  
  .btn {
    padding: 0.5rem 1.5rem;
    margin-top: 2rem;
    background: #DE6E4B;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #c85e3b;
  }
  
  .btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .btntext {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1.5rem;
    }
  
    .heading {
      font-size: 36px;
      line-height: 44px;
    }
  
    .input {
      font-size: 14px;
    }
  
    .checktext {
      font-size: 11px;
    }
  
    .btntext {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 1rem;
    }
  
    .heading {
      font-size: 28px;
      line-height: 36px;
    }
  
    .input {
      font-size: 12px;
    }
  
    .checktext {
      font-size: 10px;
    }
  
    .btntext {
      font-size: 16px;
    }
  }