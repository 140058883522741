.searchbarContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 0.5rem;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .inputGroup {
    display: flex;
    align-items: center;
    flex: 1 1 200px;
    margin: 0.5rem;
    position: relative;
  }
  
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
  }
  
  .dropdownContainer {
    position: relative;
    flex-grow: 1;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .dropdownItem {
    padding: 0.5rem;
    cursor: pointer;
  }
  
  .dropdownItem:hover {
    background-color: #f5f5f5;
  }
  
  .passengerGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 200px;
    margin: 0.5rem;
  }

  @media(max-width:768px) {
    .passengerGroup{
      justify-content: start;
      gap:1rem;
    }
  }
  
  .passengerIcon {
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .passengerText {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4A4A4A;
    white-space: nowrap;
    margin: 0 0.5rem;
  }
  
  .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0.5rem;
    background-color: #f0f0f0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: background-color 0.3s ease;
  }
  
  .searchButton:hover {
    background-color: #e0e0e0;
  }
  
  .searchButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
    .searchbarContainer {
      flex-direction: column;
      align-items: stretch;
    }
  
    .inputGroup, .passengerGroup {
      flex-basis: 100%;
      margin: 0.25rem 0;
    }
  
    .searchButton {
      margin-top: 1rem;
      align-self: center;
    }
  }