.sliderContainer {
    max-width: 100%;
    padding: 2rem;
    background-color: #000;
    color: #fff;
}

.sectionTitle {
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.5rem;
}

.mainTitle {
    font-family: 'Opulent', sans-serif;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
}

.mySwiper {
    padding-bottom: 3rem;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 2rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    height: 100%;
}

.imageWrapper {
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: 8px;
}

.cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    background: linear-gradient(94.97deg, #FFFFFF 26.31%, rgba(255, 255, 255, 0.4) 110.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.para {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #FFFFFF;
}

.navigationButtons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
}

.navButton {
    color: #808080;
    font-size: 2.5rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

.navButton:hover {
    color: #ffffff;
}

@media (max-width: 1024px) {
    .mainTitle {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .card {
        padding: 0.75rem;
    }
    
    .title {
        font-size: 1.25rem;
    }
    
    .para {
        font-size: 0.875rem;
    }
}

@media (max-width: 640px) {
    .sliderContainer {
        padding: 1rem;
    }
    
    .mainTitle {
        font-size: 1.5rem;
    }
    
    .navigationButtons {
        gap: 1rem;
    }
    
    .navButton {
        font-size: 2rem;
    }
}