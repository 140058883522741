.sliderContainer {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.list {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    position: relative;
}

.listHeader {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    cursor: pointer;
    align-items: center;
}

.listheading {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

.listserialno {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 44px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.mySwiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.slideContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.imageWrapper {
    width: 100%;
    max-width: 600px;
}

.slideImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.slideText {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.sliderhead {
    font-family: 'Opulent', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 39px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.sliderpara {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    max-width: 80%;
    margin: 0 auto;
}

.navigationWrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    justify-content: space-between;
    width: 40%;
    z-index: 1000;
}

.navigationWrapper svg {
    cursor: pointer;
}

@media (max-width: 768px) {
    .list {
        padding: 1rem;
    }

    .listHeader {
        gap: 1rem;
    }

    .listheading, .listserialno {
        font-size: 22px;
        line-height: 34px;
    }

    .sliderhead {
        font-size: 20px;
        line-height: 32px;
    }

    .sliderpara {
        font-size: 14px;
        line-height: 24px;
    }

    .navigationWrapper {
        width: 60%;
        bottom: 1rem;
    }
}

@media (max-width: 480px) {
    .listheading, .listserialno {
        font-size: 18px;
        line-height: 28px;
    }

    .sliderhead {
        font-size: 18px;
        line-height: 28px;
    }

    .sliderpara {
        font-size: 12px;
        line-height: 20px;
    }

    .navigationWrapper {
        width: 80%;
    }
}